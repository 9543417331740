.overlay {
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Dark overlay */
.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.landing {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/bg_landing_1.png);
  /* background-size: cover; */
  background-position: top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #1a3664;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem;
  margin: 0;
  z-index: 2;
}

.landing-title {
  color: '#d3c8ad';
  font-family: 'Cinzel Decorative';
  font-size: '4rem';
}

.landing-text {
  justify-content: center;
  color: #d3c8ad !important;
  font-size: 1.5rem;
  font-family: 'Roboto', 'sans-serif';
  padding: 0 1.5rem 0 1.5rem;
}

.socials {
  width: 100vh;
  height: 60vh;
  margin-bottom: 2rem;
  padding: 5rem;
}

.icon-design {
  font-size: 2rem;
  color: #000;
}

/* Responsive styles */
@media (max-width: 992px) {
  .overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  .landing {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
}

@media screen and (max-width: 530px) {
  .landing-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 425px) {
  .landing-text {
    font-size: 1rem;
  }
  .landing-title {
    font-size: 2rem;
    color: #d3c8ad;
  }
}
