.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App section {
  width: 100%;
}

footer {
  width: 100%;
  background: #d3c8ad;
  z-index: -9999;
  color: #fff;
}

.footer-text {
  color: #000 !important;
}
