.contact-landing {
  width: 100vh;
  height: 80vh;
  margin: 0;
  padding: 3rem;
  background: url('../../assets/contact.png');
  background-color: #1a3664;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top left;
  background-size: cover;
  text-align: center;
  color: #fff;
}

.socials-contact {
  width: 100vh;
  height: 40vh;
  margin: 0;
  padding: 3rem;
}
