.about-landing {
  width: 100%;
  height: 100vh;
  padding-bottom: 5rem;
  height: 100vh;
  background-image: url('../../assets/rsz_em_meet.png');
  /* background-size: cover; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* background-color: #d3c8ad; */
  background-color: #ebe6da;
  background-position: center center;
  /* background-blend-mode: soft-light; */
  position: relative;
  color: #fff;
}

.time {
  width: 70%;
  background: #d3c8ad;
  padding: 5rem;
}
