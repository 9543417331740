.overlay {
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Dark overlay */
.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.books-landing {
  width: 100%;
  height: 100vh;
  background: #d3c8ad;
  background-image: url(../../assets/bg_book.png);
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
  color: #fff;
  text-align: center;
  margin-top: 5rem;
  /* z-index: -1; */
}

.books-landing-text {
  font-family: 'Cinzel Decorative';
  color: '#172d53';
  margin-top: 12rem;
}

.books-landing-content p {
  color: #000;
  text-align: center;
}

.books-review {
  padding: 1.5rem 0 1.5rem 0;
  padding: 3rem;
}

.books-progress {
  width: 100%;
  padding: 0 0 2rem 0;
  background: #d3c8ad;
}

@media (max-width: 992px) {
  .books-landing-content {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 20%);
    text-align: center;
  }

  .books-landing-content p {
    color: #fff;
    text-align: center;
  }

  .books-landing-text {
    font-size: 1rem;
    color: #fff !important;
    align-items: center;
    margin-top: 4.5rem;
  }

  .overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    margin-top: 5rem;
  }
}
