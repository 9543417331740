/* Navbar CSS */
.nav {
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  color: #d3c8ad;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  z-index: 9999;
  transition: background-color 0.3s ease;
}

.scroll {
  background-color: #d3c8ad;
  color: #000;
}

.site-title {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0.5rem 0 0 0.5rem;
  color: #d3c8ad;
}

.scroll-title {
  color: #000;
}

.navbar-brand a {
  /* color:#d3c8ad; */
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
}

.navbar-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: #d3c8ad;
  font-size: 1.5rem;
}

.navbar-brand button {
  color: transparent;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 1rem;
  color: #000;
}

.navbar-links a {
  display: flex;
  align-items: center;
  height: 100%;
  color: inherit;
  text-decoration: none;
  padding: 0 15px;
  padding: 0.25rem;
}

.nav li.active {
  background-color: #fff;
}
.nav li:hover {
  background-color: #1a3664;
  transition: 0.5s;
}

/* Responsive Styles */
@media screen and (max-width: 770px) {
  .navbar-brand a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .navbar-links.active {
    display: flex;
    background: #172d53;
    align-items: center;
    position: fixed;
    top: 2.6rem;
  }

  .nav a.active,
  ul.active {
    text-decoration: none;
    list-style: none;
    background: #d3c8ad;
  }

  .navbar-brand button {
    color: black;
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
  }

  .toggle-icon {
    color: #fff !important;
  }
}
