.comments-section {
  width: 100%;
  height: 20rem;
  margin-top: 3rem;
  padding: 3rem;
}

/* custom arrows for prev and next */
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: 2rem;
  color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: #000;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: #000;
}

@media screen and (max-width: 768px) {
  .comments-section {
    width: 100%;
    margin: 0;
    padding-top: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 576px) {
  p {
    font-size: 0.75rem;
  }
}
