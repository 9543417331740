.right-image-section {
  width: 100%;
  /* padding: 1rem 0 1rem 0; */
  height: auto;
  background: #d3c8ad;
  overflow-y: hidden;
}

.image-section {
  width: 100%;
  height: 80vh;
  position: relative;
}

.image-style {
  width: 100%;
  display: block;
  object-fit: contain;
}

.r-text {
  width: 100%;
  justify-content: center;
  color: #000 !important;
  font-size: 1.35rem;
  font-weight: bold;
  font-family: 'Roboto', 'sans-serif';
  padding: 0 1.5rem 0 1.5rem;
}

@media screen and (max-width: 992px) {
  .image-style {
    width: 100%;
    height: 10rem;
    object-fit: cover;
  }
  .image-section {
    overflow-y: hidden;
  }
  .r-text {
    font-size: 1.3rem;
  }
}
