.blog-landing {
  width: 100%;
  height: 50vh;
  margin: 0;
  padding: 3rem;
  background: url('../../assets/bg_blog.jpg');
  background-size: cover;
  background-color: #1a3664;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top left;
  text-align: center;
  color: #fff;
}

.blog-list {
  width: 100%;
  height: 50%;
}
